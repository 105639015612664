<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
</style>
